<template>
  <div>
    <vx-card title="🏢📥 Ingreso y edición de niveles" title-color="black">
      <p class="mb-1">
        En esta sección usted puede crear niveles de forma individual utilizando el formulario como también editar y eliminar elementos.
      </p>

    </vx-card>
    <vs-row>
        <vs-col class="mt-4"  vs-align="center" vs-lg="4" vs-sm="6" vs-xs="12" >
               <IngresarNivel />
        </vs-col>
        <vs-col class="mt-4"  vs-align="center" vs-lg="8" vs-sm="6" vs-xs="12" >
                <ListadoNiveles />
        </vs-col>
    </vs-row>
  </div>
</template>

<script>
import IngresarNivel from "./IngresarNivelComponent";
import ListadoNiveles from "./ListadoNivelesComponent";

export default {
  data() {
    return {};
  },
  components: {
    IngresarNivel,
    ListadoNiveles
  },
  computed: {
    obtenerNombreProyecto() {
      return this.$store.state.ui.proyectName;
    },
    obtenerIdProyecto() {
      return this.$store.state.ui.proyectID;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
