<template>
  <div class="px-2 mt-4">
    <div class="p-4">
      <h5>Listado de Niveles</h5>
      <vs-divider></vs-divider>
    </div>
    <div v-show="true" id="data-list-thumb-view" class="data-list-container">
      <vs-table
        ref="table"
        single
        v-model="selected"
        pagination
        :max-items="itemsPerPage"
        :data="getProyect.levels"
        noDataText="🏢 No hay Niveles creados..."
      >
        <div slot="header"></div>
        <template slot="thead">
          <vs-th>Plano</vs-th>
          <vs-th>Nivel</vs-th>
          <vs-th>Aptos.</vs-th>
          <vs-th>Acciones</vs-th>
        </template>
        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="level" :key="index" v-for="(level, index) in data">
              <vs-td class="img-container">
                <img :src="level.plane_img_url" class="plane" />
              </vs-td>

              <vs-td>
                <h5 class="product-name font-medium">{{level.number_of_level}}</h5>
              </vs-td>

              <vs-td>
                <h5 class="product-name font-medium">{{level.inventory.length}}</h5>
              </vs-td>

              <vs-td>
                <vs-row>
                  <vx-tooltip color="success" text="Ver Apartamentos" position="bottom">
                    <vs-button
                      color="success"
                      type="flat"
                      icon-pack="feather"
                      icon="icon-home"
                      class="p-1"
                      :to="{ name: 'Agregar Inventario a Nivel' , params:{nivelID: `${level._id}`}}"
                      @click.native="selectedLevel(level._id, level.number_of_level)"
                    ></vs-button>
                  </vx-tooltip>
                  <vx-tooltip color="primary" text="Editar Nivel" position="bottom">
                    <vs-button
                      color="primary"
                      type="flat"
                      icon-pack="feather"
                      icon="icon-edit"
                      class="p-1"
                      @click.native="showEdit(selected._id)"
                    ></vs-button>
                  </vx-tooltip>
                  <vx-tooltip color="danger" text="Eliminar Nivel" position="bottom">
                    <vs-button
                      ref="loadableButton"
                      id="button-with-loading"
                      color="danger"
                      type="flat"
                      icon-pack="feather"
                      icon="icon-trash"
                      class="p-1 vs-con-loading__container"
                      @click.native="popupDeleteLevel = true, selectedLevel = selected._id"
                    ></vs-button>
                  </vx-tooltip>
                </vs-row>
              </vs-td>
            </vs-tr>
          </tbody>
          <div>
            <vs-popup title="Eliminación de unidad" :active.sync="popupDeleteLevel">
              <vs-row>
                <h5>¿Deseas eliminar este nivel? esta función eliminara la unidad y todas sus unidades internas.</h5>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                  <vs-button
                    color="danger"
                    @click.native="deleteLevel(selectedLevel)"
                    class="mt-2"
                    icon-pack="feather"
                    icon="icon-trash"
                  >Sí, eliminar</vs-button>
                </vs-col>
              </vs-row>
            </vs-popup>
          </div>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
      selected: [],
      getProyect: [],
      itemsPerPage: 15,
      backgroundLoading: "danger",
      colorLoading: "#fff",
      popupDeleteLevel: false,
    };
  },
  computed: {
    editarInventarioNivel() {
      if (this.selected == "") {
        return false;
      } else {
        return true;
      }
    },
  },
  apollo: {
    getProyect: {
      query: gql`
        query($proyectID: String!) {
          getProyect(proyectID: $proyectID) {
            _id
            levels {
              _id
              proyectID
              number_of_level
              plane_img_url
              inventory {
                _id
              }
            }
          }
        }
      `,
      variables() {
        return {
          proyectID: localStorage.proyectID,
        };
      },
      pollInterval: 300,
    },
  },
  methods: {
    showEdit(id) {
      const payload = {
        showEdit: true,
        id: id,
      };
      this.$store.dispatch("mostrarEditarNivel", payload);
    },
    openLoadingContained() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45,
      });
    },
    selectedLevel(param, number) {
      localStorage.level = number;
      localStorage.selectedLevel = param;
    },
    deleteLevel(l) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($proyectID: ID!, $levelID: ID!) {
              deleteLevel(proyectID: $proyectID, levelID: $levelID) {
                _id
              }
            }
          `,
          // Parametros que vienen de mi model
          variables: {
            proyectID: localStorage.proyectID,
            levelID: l,
          },
        })
        .then(() => {
          this.$vs.loading.close("#button-with-loading > .con-vs-loading");
          this.$vs.notify({
            time: 4000,
            title: "Eliminación correcta",
            text: "Nivel eliminado del proyecto.",
            color: "success",
            iconPack: "feather",
            icon: "icon-trash",
          });
          this.popupDeleteLevel = false;
        })
        .catch(() => {
          //console.error(error)
        });
    },
  },
  destroyed() {
    const payload = {
      showEdit: false,
      id: "0",
    };
    this.$store.dispatch("mostrarEditarNivel", payload);
  },
};
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 0px;
      margin-right: 0px;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: left;
            }

            .plane {
              padding: 5px;
              height: 150px;
            }
          }
        }
        td.td-check {
          padding: 10px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
    .dropdown-button-container {
      display: flex;
      align-items: center;

      .btnx {
        border-radius: 5px 0px 0px 5px;
      }

      .btn-drop {
        border-radius: 0px 5px 5px 0px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    .vs-checkbox-small .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
      margin-top: 0vh !important;
    }
  }
}
</style>