<template>
  <div class="sm:w-full mt-4 mb-3">
    <vs-button
      v-show="mostrarEditorNivel"
      color="success"
      icon-pack="feather"
      @click="showEdit"
      class="m-2 mb-4"
      icon="icon-plus"
    >Crear nivel</vs-button>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" class="px-2">
        <!-- ///Creacion de Nivel/// -->
        <!-- ///Primer Tarjeta/// -->

        <vx-card v-show="!mostrarEditorNivel">
          <vs-col vs-type="flex" vs-justify="center">
            <h3 class="mt-4 mb-4">Crear un Nivel</h3>
          </vs-col>
          <br />
          <div class="p-2">
            <vs-divider></vs-divider>
          </div>
          <vs-row>
            <vs-col class="w-full px-3">
              <div class="w-full sm:w-full mb-4">
                <vs-input
                  size="large"
                  icon-pack="feather"
                  class="w-full"
                  icon="icon-layers"
                  label="Número de Nivel:"
                  color="primary"
                  type="number"
                  placeholder="Número"
                  v-model="numero_nivel"
                  v-validate="'required'"
                  name="Primer_Nombre"
                />
              </div>
            </vs-col>
            <vs-col class="sm:w-full">
              <div class="w-full">
                <div>
                  <img v-if="!imageHeaderReadyToUplad" src class="responsive rounded-lg" />
                  <img
                    v-if="imageHeaderReadyToUplad"
                    :src="perfilHeaderTemp"
                    class="responsive rounded-lg"
                  />
                </div>
                <div class="text-center mt-4">
                  <p
                    class="text-grey"
                    v-if="!imageHeaderReadyToUplad"
                  >Plano de Nivel {{numero_nivel}}</p>
                  <p v-if="!imageHeaderReadyToUplad">Crop Perfecto 1000x480</p>
                  <p class="text-grey" v-if="imageHeaderReadyToUplad"></p>
                </div>

                <vs-button
                  @click="$refs.btnHeader.click()"
                  v-if="!imageHeaderReadyToUplad"
                  class="w-full mt-6"
                  color="success"
                  icon-pack="feather"
                  icon="icon-upload-cloud"
                >Cargar imagen</vs-button>
              </div>
              <input
                type="file"
                accept="image/png, image/jpeg"
                ref="btnHeader"
                v-show="false"
                @change="mostrarImagenHeader($event)"
              />
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col>
              <vs-button
                v-show="mostrarGuardar"
                :disabled="btnUploadLevel"
                ref="loadableButton"
                id="button-with-loading"
                class="vs-con-loading__container w-full mb-4"
                color="success"
                icon-pack="feather"
                icon="icon-save"
                @click.native="guardarNivel()"
              >Guardar Nivel: {{numero_nivel}}</vs-button>
              <vs-button
                v-if="imageHeaderReadyToUplad"
                class="mt-1 w-full"
                color="danger"
                icon-pack="feather"
                icon="icon-x-circle"
                @click="cancelarHeader"
              >Cancelar</vs-button>
            </vs-col>
          </vs-row>
        </vx-card>
      </vs-col>

      <!-- ///Edicion de Nivel/// -->
      <!-- ///Segunda Tarjeta/// -->

      <vx-card v-if="mostrarEditorNivel">
        <vs-col vs-type="flex" vs-justify="center">
          <p class="mt-4 mb-0">{{getProyect.name}}</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center">
          <h3 class="mt-1 mb-4">Edición de nivel: {{getLevel.number_of_level}}</h3>
        </vs-col>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-chip>{{bringLevelForEdit}}</vs-chip>
          </vs-col>
        </vs-row>
        <div class="p-2">
          <vs-divider></vs-divider>
        </div>
        <vs-row>
          <vs-col class="flex w-full px-3">
            <div class="w-full sm:w-full mb-4">
              <vs-input
                size="large"
                icon-pack="feather"
                class="w-full"
                icon="icon-layers"
                label="Número de Nivel:"
                color="primary"
                type="number"
                :placeholder="getLevel.number_of_level"
                v-model="E_numero_nivel"
                v-validate="'required'"
                name="Primer_Nombre"
              />
            </div>
            <vs-button
              @click="updateLevel('number_of_level', E_numero_nivel, 'de Nivel')"
              :disabled="!(saveLevel)"
              color="success"
              icon-pack="feather"
              class="mt-8 m-2"
              icon="icon-save"
            ></vs-button>
          </vs-col>
          <vs-col class="sm:w-full">
            <div class="w-full">
              <div>
                <img v-if="!imageHeaderReadyToUplad2" src class="responsive rounded-lg" />
                <img
                  v-if="imageHeaderReadyToUplad2"
                  :src="perfilHeaderTemp2"
                  class="responsive rounded-lg"
                />
              </div>
              <div class="text-center mt-4">
                <img
                  v-if="!imageHeaderReadyToUplad2"
                  :src="getLevel.plane_img_url"
                  class="responsive rounded-lg"
                />
              </div>

              <vs-button
                @click="$refs.btnHeader.click()"
                v-if="!imageHeaderReadyToUplad2"
                class="w-full mt-6"
                color="success"
                icon-pack="feather"
                icon="icon-upload-cloud"
              >Remplazar imagen</vs-button>
            </div>
            <input
              type="file"
              accept="image/png, image/jpeg"
              ref="btnHeader"
              v-show="false"
              @change="mostrarImagenHeader2($event)"
            />
          </vs-col>
        </vs-row>
        <br />
        <vs-row>
          <vs-col>
            <vs-button
              v-show="mostrarGuardar2"
              :disabled="btnUploadLevel"
              ref="loadableButton"
              id="button-with-loading"
              class="vs-con-loading__container w-full"
              color="success"
              icon-pack="feather"
              icon="icon-save"
              @click="guardarNivel2(), openLoadingContained(), btnUploadLevel = false"
            >Guardar Nivel: {{numero_nivel}}</vs-button>
            <vs-button
              v-if="imageHeaderReadyToUplad2"
              class="mt-1 w-full"
              color="danger"
              icon-pack="feather"
              icon="icon-x-circle"
              @click="cancelarHeader2"
            >Cancelar</vs-button>
          </vs-col>
        </vs-row>
      </vx-card>
    </vs-row>
  </div>
</template>

<script>
import S3 from "aws-s3";
import gql from "graphql-tag";
import slugify from "../../../helpers/slugify";

export default {
  data() {
    return {
      numero_nivel: null,
      E_numero_nivel: null,
      imageHeaderReadyToUplad: false,
      imageHeaderReadyToUplad2: false,
      perfilHeaderTemp: "",
      perfilHeaderTemp2: "",
      imageReadyToEdit: false,
      imageReadyToEdit2: false,
      imageForEdit: "",
      perfilHeader: null,
      perfilHeader2: null,
      btnUploadLevel: false,
      getLevel: [],
      getProyect: [],
    };
  },
  methods: {
    openLoadingContained() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.5,
      });
    },
    mostrarImagenHeader() {
      this.perfilHeader = null;
      this.perfilHeader = event.target.files[0];
      this.imageHeaderReadyToUplad = true;
      const render = new FileReader();
      render.readAsDataURL(this.perfilHeader);
      render.onload = (e) => {
        this.perfilHeaderTemp = e.target.result;
      };
    },
    mostrarImagenHeader2() {
      this.perfilHeader2 = null;
      this.perfilHeader2 = event.target.files[0];
      this.imageHeaderReadyToUplad2 = true;
      const render = new FileReader();
      render.readAsDataURL(this.perfilHeader2);
      render.onload = (e) => {
        this.perfilHeaderTemp2 = e.target.result;
      };
    },
    cancelarHeader() {
      this.perfilHeader = "";
      this.imageReadyToEdit = false;
    },
    cancelarHeader2() {
      this.perfilHeader2 = "";
      this.imageHeaderReadyToUplad2 = false;
    },
    updateLevel(fieldForUpdate, valueToAdd, descriptionToUser) {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($levelID: ID!, $field: String!, $value: String!) {
              updateLevel(
                levelID: $levelID
                objectField: $field
                value: $value
              ) {
                objectField
                value
              }
            }
          `,
          // Parameters
          variables: {
            levelID: this.bringLevelForEdit,
            field: fieldForUpdate,
            value: valueToAdd,
          },
        })
        .then(() => {
          this.$vs.notify({
            title: `📝 El campo ${descriptionToUser}`,
            text: `Fue editado correctamente.`,
            color: "success",
          });
          this.storage[fieldForUpdate] = valueToAdd;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async guardarNivel() {
      this.btnUploadLevel = false;
      this.openLoadingContained();
      try {
        await this.S3Client.uploadFile(
          this.perfilHeader,
          this.numero_nivel
        ).then((data) => {
          const levelImgUrl = data.location;
          this.addLevelToProyect(levelImgUrl);
        });
      } catch (err) {
        //console.log(err)
      }
    },
    async guardarNivel2() {
      try {
        await this.S3Client.uploadFile(
          this.perfilHeader2,
          this.getLevel.number_of_level
        ).then((data) => {
          const levelImgUrl = data.location;
          this.updateLevel("plane_img_url", levelImgUrl, "de imagen");
          this.cancelarHeader2();
        });
      } catch (err) {
        //console.log(err)
      }
    },

    addLevelToProyect(imgUrl) {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation(
              $developerID: String!
              $proyectID: String!
              $number_of_level: Int!
              $plane_img_url: String!
            ) {
              addLevelToProyect(
                developerID: $developerID
                proyectID: $proyectID
                number_of_level: $number_of_level
                plane_img_url: $plane_img_url
              ) {
                _id
              }
            }
          `,
          // Parameters
          variables: {
            developerID: localStorage.company_id,
            proyectID: localStorage.proyectID,
            number_of_level: parseInt(this.numero_nivel),
            plane_img_url: imgUrl,
          },
        })
        .then(() => {
          this.$vs.notify({
            time: 6000,
            title: `Nivel: ${this.numero_nivel} creado! 📥🏢`,
            text: `Puede visualizarlo en el listado de Niveles.`,
            color: "success",
          });
          this.$vs.loading.close("#button-with-loading > .con-vs-loading");
          this.numero_nivel = null;
          this.imageHeaderReadyToUplad = false;
          this.perfilHeader = null;
          this.perfilHeaderTemp = "";
          this.btnUploadLevel = false;
        })

        .catch((error) => {
          console.error(error);
        });
    },
    showEdit() {
      const payload = {
        showEdit: false,
        id: 0,
      };
      this.$store.dispatch("mostrarEditarNivel", payload);
    },
    getToLevel() {
      this.$apollo.query({
        query: gql`
          query getLevel($levelID: String!) {
            getLevel(levelID: $levelID) {
              _id
              number_of_level
              plane_img_url
              proyectID
            }
          }
        `,
        variables:{
          levelID: this.bringLevelForEdit,
        }
      }).then((data) => {
        this.getLevel = data.data.getLevel;
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  mounted(){
    this.getToLevel();
  },
  computed: {
    config() {
      const developerName = slugify(localStorage.company);
      const projectName = slugify(localStorage.proyectName);
      return {
        bucketName: "flattlo-app",
        dirName: `${developerName}/proyects/${projectName}/levels`,
        region: "us-east-1",
        accessKeyId: process.env.VUE_APP_AWS_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SK,
        s3Url: process.env.VUE_APP_S3_URL,
      };
    },
    S3Client() {
      return new S3(this.config);
    },
    newFileName() {
      return "test-file";
    },
    mostrarGuardar() {
      if (
        (this.numero_nivel == null) |
        (this.imageHeaderReadyToUplad == false)
      ) {
        return false;
      } else {
        return true;
      }
    },
    mostrarGuardar2() {
      if (this.imageHeaderReadyToUplad2 == false) {
        return false;
      } else {
        return true;
      }
    },
    mostrarEditorNivel() {
      return this.$store.state.showEditModule;
    },
    bringLevelForEdit() {
      return this.$store.state.idLevelforEdit;
    },
    saveLevel() {
      if (
        (this.getLevel.number_of_level != this.E_numero_nivel) &
        (!this.E_numero_nivel == "")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
